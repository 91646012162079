<template>
    <div>
        <label>{{ field.label }}</label>
        <input type="text" class="form-control" v-model="field.val">
    </div>
</template>

<script>
export default {
    name: "SearchTextInput",
    props: ["field"],
}
</script>

<style scoped>

</style>