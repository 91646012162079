<template>
    <div class="form_grid">
        <label><input type="checkbox" :true-value="trueValue" :false-value="''" v-model="field.val"> {{ field.label }}</label>
    </div>
</template>

<script>
export default {
    name: "SearchCheckboxInput",
    props: ["field"],
    computed: {
        trueValue: function() {
            return (this.field.trueValue ? this.field.trueValue : 1);
        }
    }
}
</script>

<style scoped>
    .form_grid {
        margin-bottom: 0;
    }

</style>