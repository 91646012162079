<template>
    <div>
        <label>{{ field.label }}</label>
        <Typeahead v-if='field.params' parseObject=true :cdi_type="field.cdiType" :params='field.params' v-bind:ID.sync="field.val" />
        <Typeahead v-else parseObject=true :cdi_type="field.cdiType" v-bind:ID.sync="field.val" />
    </div>
</template>

<script>
import Typeahead from '@/components/utils/typeaheads/Typeahead';
export default {
    name: "SearchObjectInput",
    props: {
        'field': {},
    },
    components: {
        Typeahead,
    },
}
</script>

<style scoped>

</style>