<template>
    <div>
        <label>{{ field.label }}</label>
        <select v-if="field.valuesOnly === true" class="cdi_select form-control" v-model="field.val">
            <option v-if="!field.excludeDefault" value="0">All</option>
            <option v-for="(value, index) in field.options" v-bind:key="index" :value="value">{{ value }}</option>
        </select>
        <select v-else class="cdi_select form-control" v-model="field.val">
            <option v-if="!field.excludeDefault" value="0">All</option>
            <option v-for="[value, option] in field.options" v-bind:key="value" :value="value">{{ option }}</option>
        </select>
    </div>
</template>

<script>
export default {
    name: "SearchSelectInput",
    props: ["field"]
}
</script>

<style scoped>

</style>