<template>
    <div>
        <label>{{ field.label }}</label>
        <DatePicker :inputAttributes="{class: 'form-control'}"  v-model="field.val" />
    </div>
</template>

<script>
import DatePicker from '@/components/utils/DatePicker';
export default {
    name: "SearchDateInput",
    components: {
        DatePicker,
    },
    props: ["field"],
}
</script>

<style scoped>

</style>