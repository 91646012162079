<template>
    <div>
        <label>{{ field.label }}</label>
        <Typeahead :cdi_type="field.cdiType" v-bind:ID.sync="field.val" />
    </div>
</template>

<script>
import Typeahead from '@/components/utils/typeaheads/Typeahead';
export default {
    name: "SearchTypeaheadInput",
    props: ["field"],
    components: {
        Typeahead,
    },
}
</script>

<style scoped>

</style>