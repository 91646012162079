<template>
    <div>
        <h1>{{ state.titleAlt ? state.titleAlt : state.titlePlural }}</h1>
        <form class="margin_bottom" id="search_options" name="search_options" v-show='!hideFields' @submit.prevent="search">
            <div class="panel panel-default">
                <div class="panel-heading"></div>
                <div class="row panel-body">
                    <div v-for="(field, key) in state.search.formFields" v-bind:key="key" class="col-md-3 col-sm-4 col-xs-6 form-group" v-bind:class="{ toggle_hide: toggleHide && field.toggleHide }">
                        <component :is="field.component" :field="field" />
                    </div>
                    <div class="col-xs-12" v-if="showMoreButton !== false">
                        <button type="button" @click="toggleMoreFilters" class="btn btn-default btn-sm">{{ toggleHide ? 'More' : 'Less' }} Filters</button>
                    </div>
                </div>
            </div>
            <input type="submit" value="Search" class="btn btn-primary btn-lg btn_min_width">
            <slot name="menu"></slot>
            <div v-if="state.search.sortFields" class="pull-right sort_row">
                Sort By
                <select v-model="state.search.formFields.orderBy.val" class="form-control form_control_inline">
                    <option v-for="(label, key) in state.search.formFields.orderBy.options" v-bind:key="key" :value="key">{{ label }}</option>
                </select>
                <select v-model="state.search.formFields.direction.val" class="form-control form_control_inline">
                    <option value="ASC">Ascending</option>
                    <option value="DESC">Descending</option>
                </select>
            </div>
        </form>
        <ul v-if="csv" class="nav nav-pills">
            <a><ProtectedLink :queryString="csvQs" :linkType="'link'" :text="'Export CSV'" :name="state.search.endPoint" :type="'reportCSV'" /></a>
        </ul>
        <SearchResult />
    </div>
</template>

<script>
import { store } from '@/store/BusinessObject.store.js';
import SearchResult from './SearchResult';
import SearchTextInput from './SearchTextInput';
import SearchCheckboxInput from './SearchCheckboxInput';
import SearchSelectInput from './SearchSelectInput';
import SearchDateInput from './SearchDateInput';
import SearchTypeaheadInput from './SearchTypeaheadInput';
import SearchObjectInput from './SearchObjectInput';
import helpers from "@/store/helpers";
import ProtectedLink from "@/components/utils/ProtectedLink.vue";

export default {
    name: "Search",
    components: {
        ProtectedLink,
        SearchResult,
        SearchTextInput,
        SearchCheckboxInput,
        SearchSelectInput,
        SearchDateInput,
        SearchTypeaheadInput,
        SearchObjectInput,
    },
    props: ['hideFields', 'showMoreButton', 'csv'],
    data() {
        return {
            state: store.state,
            toggleHide: true
        }
    },
    created() {
        this.search();
    },
    methods: {
        search: function() {
            this.state.search.formFields.searchIndex.val = 0;
            store.search(store.state.search.endPoint, store.state.search.formFields, 'search.results');
        },
        queryString: function() {
            store.api(store.state.search.endPoint, store.state.search.formFields)
        },
        toggleMoreFilters: function() {
            this.toggleHide = !this.toggleHide;
            let formFields = Object.entries(this.state.search.formFields)
            const defaultFormFields = [];
            if (this.toggleHide) {
                formFields.forEach(formField => {
                    if (typeof formField[1].defaultVal !== 'undefined') {
                        if (!this.$route.query.ignore_default) {
                            formField[1].val = formField[1].defaultVal;
                        }
                    }
                    defaultFormFields.push(formField);
                })
                this.state.search.formFields = Object.fromEntries(defaultFormFields);
            }
        },
    },
    computed: {
        csvQs: function() {
            var qsPairs = helpers.getQSPairs(this.state.search.formFields);

            qsPairs['params[json]'] = 1;
            if (!this.state.isGroupBy) {
                qsPairs['params[is_group_by]'] = 0;
                qsPairs['params[fields]'] = this.state.data;
            }else{
                qsPairs['params[is_group_by]'] = 1;
                qsPairs['params[group_by]'] = this.state.groupBy;
            }

            if (this.state.isCompareBy) {
                qsPairs['params[date_comparison]'] = 'on';
                qsPairs['params[period_type]'] = this.state.optionFields.compareByPeriod.val;
                qsPairs['params[date_compare_periods]'] = this.state.optionFields.numPeriod.val;
            }

            return helpers.getQSString(qsPairs);
        }
    }
}
</script>

<style scoped>

</style>
